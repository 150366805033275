import Vue from "vue";
import { mdiFileFind, mdiMonitorDashboard, mdiMonitorEye, mdiAccountQuestion } from "@mdi/js";

export default Vue.extend({
  name: "SoporteDashComponent",
  components: {
    smsDashItem: () => import("@/components/dashboard/dashItems/smsDashItem/smsDashItem.vue"),
    btnDashboard: () => import("@/components/buttons/btnDashboard.vue"),
    tonelerosDashItem: () => import("@/components/dashboard/dashItems/tonelerosDashItem/tonelerosDashItem.vue"),
    mac: () => import("@/components/cajero/macAddress.vue"),
  },
  data() {
    return {
      Icons: {
        Cajeros: mdiMonitorDashboard,
        Operaciones: mdiFileFind,
        Toneleros: mdiMonitorEye,
        Faq: mdiAccountQuestion,
      } as Record<string, string>,
    };
  },
});
